import React, {useContext, useEffect, useState} from "react";
import {FeaturedFilter} from "../../table/useTableFilters";
import {useGetNotesQuery} from "../../../store/slice/myContentDashAdminApi";
import ReportContext from "../../../context/ReportContext";
import {Box, Button, Checkbox, Divider, FormControlLabel, IconButton, TextField} from "@mui/material";
import {Search} from "@mui/icons-material";
import fieldFeaturedFilter from "./FieldFeaturedFilter";

export type PageNotesFilterValue = {
    exists?: true | false,
    contains?: string
} | null;

export type PageNotesFilterProps = {
    value: PageNotesFilterValue,
    onChange: (newValue: PageNotesFilterValue) => void;
    featuredFilter: FeaturedFilter;
    closePopover: () => void
}

const checkmarkValues = [true, false, undefined];
const PageNotesFilter = ({value, onChange, featuredFilter}: PageNotesFilterProps) => {
    const [contains, setContains] = useState(value.contains);
    useEffect(() => {
        setContains(value.contains);
    }, [value]);
    const {report} = useContext(ReportContext);
    const {isLoading} = useGetNotesQuery({reportId: report.id});

    const applySearchValue = () => {
        onChange({
            exists: undefined,
            contains: contains === "" ? undefined : contains
        })
    }

    return <Box width={"300px"} pb={2}>
        <FormControlLabel
            control={
                <Checkbox
                    disabled={isLoading}
                    indeterminate={value.exists === undefined}
                    checked={value.exists === undefined ? false : value.exists}
                    onChange={() => {
                        onChange({
                            ...value,
                            exists: checkmarkValues[(checkmarkValues.indexOf(value.exists) + 1) % checkmarkValues.length]
                        })
                    }}
                />}
            label={"Has page note"}/>
        <Divider textAlign={"center"} sx={{my: 1}}>OR</Divider>
        <Box display={"flex"} alignItems={"center"}>
            <TextField
                label={"Page note contains..."}
                size={"small"}
                sx={{mt: 1, flex: 1}}
                value={contains === undefined ? "" : contains}
                onChange={(event) => setContains(event.target.value)}
            />
            <IconButton sx={{mt: 1}} disabled={isLoading} onClick={applySearchValue}>
                <Search/>
            </IconButton>
        </Box>
        <Button
            variant={"text"}
            sx={{mt: 1}}
            onClick={() => onChange(fieldFeaturedFilter.getDefaultValue(featuredFilter) as PageNotesFilterValue)}
        >Reset</Button>
    </Box>;
}

export default PageNotesFilter;