import React from "react";
import ReportTableRow from "./ReportTableRow";
import {Box, TableBody, TableCell, TableRow, Typography} from "@mui/material";
import {Row} from "./useReportTable";
import {SelectedColumnFieldOptions} from "./ReportTable";
import Skeleton from "@mui/material/Skeleton";
import Column from "../../types/Column";
import Table from "../../types/Table";

export type ReportTableBodyProps = {
    isLoading: boolean,
    rows: Row[],
    dataSource: any,
    displayColumns: Column[],
    displayedHiddenColumnIds: number[],
    error: string | null,
    selectedColumnFieldOptions: SelectedColumnFieldOptions,
    onRankingsClick?: (row: Row, columns: Column[]) => void,
    table: Table,
    showActionsCell: boolean;
}

const ReportTableBodyMessage = ({message, colSpan}) => (<TableBody>
    <TableRow>
        <TableCell colSpan={colSpan}>
            <Box sx={{height: "100%"}}>
                <Typography color={"error"}>{message}</Typography>
            </Box>
        </TableCell>
    </TableRow>
</TableBody>)

const ReportTableBody = ({
                             isLoading,
                             rows,
                             dataSource,
                             displayColumns,
                             error,
                             selectedColumnFieldOptions,
                             onRankingsClick,
                             table,
                             showActionsCell
                         }: ReportTableBodyProps) => {
    let rowElements;
    const columnCount = displayColumns.length + (showActionsCell ? 1 : 0);

    if (error !== null) {
        return <ReportTableBodyMessage message={error} colSpan={displayColumns.length}/>
    }
    if (rows.length === 0 && !isLoading) {
        return <ReportTableBodyMessage
            message={"No rows match the current filters/query"}
            colSpan={columnCount}
        />
    }
    if (isLoading) {
        const rowKeys = Array.from({length: 8});
        rowElements = rowKeys.map((key, index) =>
            <TableRow key={index}>
                {Array.from(Array(columnCount).keys()).map(index => (
                    <TableCell key={index}><Skeleton height={"40px"} animation={"wave"}/></TableCell>
                ))}
            </TableRow>
        );
    } else {
        rowElements = rows.map((row) => (
            <ReportTableRow
                columns={displayColumns.sort((a, b) => a.position - b.position)}
                row={row}
                key={row.hash}
                fields={dataSource.fields}
                selectedColumnFieldOptions={selectedColumnFieldOptions}
                onRankingsClick={onRankingsClick}
                table={table}
                showActionsCell={showActionsCell}
            />
        ));
    }

    return <TableBody>
        {rowElements}
    </TableBody>
};

export default ReportTableBody;