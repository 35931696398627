import {MutableRefObject, useCallback, useContext, useEffect, useMemo, useState} from "react";
import md5 from 'md5';
import {
    FeaturedFilter,
    FeaturedFilterInput,
    FilterData,
    ServerFormattedFeaturedFilterInput,
    useTableFilters
} from "./useTableFilters";
import {UniqueFilterOptionsValue} from "../report/filters/UniqueFilterOptions";
import {GetRowsParams} from "../../api/ReportsApi";
import {Export, useTableExport as useTableExportHook} from "./useTableExport";
import Table from "../../types/Table";
import FieldFeaturedFilter from "../report/filters/FieldFeaturedFilter";
import {GetFilterOptionCountParams, useGetNotesQuery} from "../../store/slice/myContentDashAdminApi";
import useGetRowsQueryWithLocalstorage from "../../hooks/useGetRowsQueryWithLocalstorage";
import useSearchBarValue from "../../hooks/useSearchBarValue";
import {PageNotesFilterValue} from "../report/filters/PageNotesFilter";
import Note from "../../types/Note";
import ReportContext from "../../context/ReportContext";
import {useAppDispatch, useAppSelector} from "../../store/store";
import {tableInputSelector, updateTableInput} from "../../store/slice/filtersSlice";

export type SortByData = {
    [columnId: number]: "asc" | "desc"
}

export type Row = {
    [fieldName: string]: string | number,
    hash: string
}

export type PageOptions = {
    page: number,
    perPage: number
}

export type TableContentsResponse = {
    rows: Row[],
    totalRows: number,
    tableSortBy: SortByData
}

export type FilterOptionCount = {
    featuredFilterId: number,
    optionId: number,
    count: number
}

const useServerFormattedFeaturedFilterOptions = (featuredFilters: FeaturedFilter[], featuredFilterInput: FeaturedFilterInput[]) => {
    const {report} = useContext(ReportContext);
    const {data: pageNotes} = useGetNotesQuery({reportId: report.id});
    return useMemo(() => formatFeaturedFilterOptionsForServer(
        featuredFilters,
        featuredFilterInput,
        pageNotes?.notes
    ), [featuredFilters, featuredFilterInput, pageNotes]);
}


/**
 * Formats the featured filters and featured filter input in a format accepted by the server.
 * @param featuredFilters
 * @param featuredFilterInput
 * @param pageNotes
 */
const formatFeaturedFilterOptionsForServer = (featuredFilters: FeaturedFilter[], featuredFilterInput: FeaturedFilterInput[], pageNotes?: Note[]) => {
    const filter_options: ServerFormattedFeaturedFilterInput[] = featuredFilters
        .filter(featuredFilter => featuredFilter.type === "filter_options")
        .map(featuredFilter => ({
            id: featuredFilter.id,
            selected_options: featuredFilterInput.find(({featuredFilterId}) => featuredFilterId === featuredFilter.id).input
        }));

    const uniqueDatabaseOptions: ServerFormattedFeaturedFilterInput[] = featuredFilters
        .filter(({type}) => ["unique_database_options", "unique_database_options_csv", "unique_database_options_ssv"].includes(type))
        .map(({id, cache_key}) => {
            const {
                selected,
                mode
            } = (featuredFilterInput.find(({featuredFilterId}) => featuredFilterId === id).input as UniqueFilterOptionsValue);
            const useSelectedMode = (mode === 'selected');

            return {
                id,
                cache_key,
                [useSelectedMode ? "selected_options" : "deselected_options"]: selected
            } as ServerFormattedFeaturedFilterInput;
        });

    const pageNoteFilter: ServerFormattedFeaturedFilterInput[] = featuredFilters
        .filter(({type}) => type === "page_note")
        .map(filter => {
            if (!pageNotes) {
                return undefined;
            }
            const input = featuredFilterInput.find(({featuredFilterId}) => featuredFilterId === filter.id);
            const inputValue = input.input as PageNotesFilterValue;
            if (!inputValue.contains) {
                if (inputValue.exists === undefined) {
                    return undefined;
                }

                return {
                    id: filter.id,
                    page_urls: pageNotes.map(note => note.page_url),
                    mode: inputValue.exists ? "page_url_is" : "page_url_is_not"
                }
            } else {
                console.log("Has contains");
                return {
                    id: filter.id,
                    page_urls: pageNotes.filter(({text}) => (
                        text.toLowerCase().includes(inputValue.contains.toLowerCase())
                    )).map(note => note.page_url),
                    mode: "page_url_is"
                }
            }
        });

    return filter_options.concat(uniqueDatabaseOptions).concat(pageNoteFilter).filter(filter => filter !== undefined);
}

export type UseTableExportHook = (
    onError: (errorMessage: string) => void,
    onExportCompleted: (downloadExport: () => Promise<void>, exportJob: Export) => void
) => {
    isExportInProgress: boolean,
    startExport: (
        inheritFilters: boolean,
        inheritSortOptions: boolean
    ) => void,
    activeExport: Export,
    cancelExport: () => void
}

export type SearchBarInput = {
    searchTerm: string;
    columnIds: number[];
    useAdvancedSearch: boolean;
}

export type UseFilterHashProps = {
    userAdvancedFilterReducer?: (userAdvancedFilters: FilterData) => FilterData,
    searchReducer?: (search: SearchBarInput) => SearchBarInput,
    featuredFilterInputReducer?: (featuredFilterInputs: FeaturedFilterInput[]) => FeaturedFilterInput[]
}

export const useReportTable = (
    table: Table,
    dataSource,
    reportId: number,
    onRowError: (message: string) => void,
    onError: (error: Error) => void,
    ref: MutableRefObject<any>
) => {
    const searchBarValue = useSearchBarValue();
    const dispatch = useAppDispatch();
    const [pageOptions, setPageOptions] = useState<PageOptions>({
        page: 0,
        perPage: 50
    });

    const {sortByData: sortBy} = useAppSelector(tableInputSelector(table.id));
    const setSortBy = (sortBy: SortByData) => {
        dispatch(updateTableInput({
            tableId: table.id,
            input: {
                sortByData: sortBy
            }
        }));
    }

    const {
        advancedFilters,
        tableFeaturedFilters,
        featuredFilterInput,
        updateFeaturedFilterInput,
        activeTablePresetId,
        setActiveTablePresetId,
        setAdvancedFilters,
        userAdvancedFilters,
        generatedFiltersLoaded,
        defaultFeaturedFilterInput
    } = useTableFilters(table, dataSource, reportId, ref, onError);
    const serverFormattedFilterData: ServerFormattedFeaturedFilterInput[] = useServerFormattedFeaturedFilterOptions(
        tableFeaturedFilters,
        featuredFilterInput
    );

    const useTableExport: UseTableExportHook = (
        onError: (errorMessage: string) => void,
        onExportCompleted: (downloadExport: () => Promise<void>, exportJob: Export) => void,
    ) => useTableExportHook({
        reportId,
        table,
        onError,
        advancedFilterData: advancedFilters,
        sortBy,
        featuredFilterInput: serverFormattedFilterData,
        onExportCompleted,
        ref
    });

    const requestParameters = useMemo(() => ({
        reportId: reportId,
        tableId: table.id,
        perPage: pageOptions.perPage,
        page: pageOptions.page,
        filterData: advancedFilters,
        sortByData: sortBy,
        featuredFilterOptions: serverFormattedFilterData,
        search: {
            search_term: searchBarValue.searchTerm,
            column_ids: searchBarValue.columnIds,
            use_advanced_search: searchBarValue.useAdvancedSearch
        },
    }) as GetRowsParams, [
        advancedFilters,
        pageOptions.page,
        pageOptions.perPage,
        reportId,
        searchBarValue,
        sortBy,
        table.id,
        serverFormattedFilterData
    ]);

    const {data: tableContentsResponse, lastKnownRowCount, isLoading} = useGetRowsQueryWithLocalstorage({
        cacheExpirationTime: table.last_modified,
        requestParameters,
        skip: !generatedFiltersLoaded || table.disabled
    });

    const useFilterHash = useMemo(() => ({
                                             userAdvancedFilterReducer = (userAdvancedFilters: FilterData) => userAdvancedFilters,
                                             searchReducer = (search: SearchBarInput) => search,
                                             featuredFilterInputReducer = (featuredFilterInputs: FeaturedFilterInput[]) => featuredFilterInputs
                                         }: UseFilterHashProps
    ) => {
        let sourceString = "";
        sourceString += JSON.stringify(userAdvancedFilterReducer(userAdvancedFilters));
        sourceString += JSON.stringify(searchReducer(searchBarValue));
        sourceString += JSON.stringify(featuredFilterInputReducer(featuredFilterInput));

        return md5(sourceString);
    }, [featuredFilterInput, searchBarValue, userAdvancedFilters]);

    const getFeaturedFilterCountParams = useCallback((featuredFilter: FeaturedFilter): GetFilterOptionCountParams => {
        const defaultInput = FieldFeaturedFilter.getDefaultValue(featuredFilter);

        const featuredFilterOptions = formatFeaturedFilterOptionsForServer(
            tableFeaturedFilters,
            featuredFilterInput.map(ffi => ffi.featuredFilterId === featuredFilter.id ? {
                ...ffi,
                input: defaultInput
            } : ffi)
        );

        return {
            reportId: reportId,
            tableId: table.id,
            filterData: advancedFilters,
            featuredFilterOptions: featuredFilterOptions,
            search: {
                search_term: searchBarValue.searchTerm,
                column_ids: searchBarValue.columnIds,
                use_advanced_search: searchBarValue.useAdvancedSearch
            },
            filterId: featuredFilter.id
        };
    }, [advancedFilters, featuredFilterInput, reportId, searchBarValue.columnIds, searchBarValue.searchTerm, searchBarValue.useAdvancedSearch, table.id, tableFeaturedFilters]);

    useEffect(() => {
        const currentRef = ref.current;
        return () => {

            if (currentRef.filterOptionsCountAbortController) {
                currentRef.filterOptionsCountAbortController.abort();
            }
        }
    }, [ref]);

    return {
        isLoading: table.disabled ? false : !generatedFiltersLoaded || (tableContentsResponse === undefined || isLoading),
        pageOptions,
        setPageOptions,
        tableFeaturedFilters,
        updateFeaturedFilterInput,
        featuredFilterInput,
        activeTablePresetId,
        setActiveTablePresetId,
        advancedFilters,
        userAdvancedFilters,
        setAdvancedFilters,
        sortBy,
        setSortBy,
        tableContentsResponse,
        useTableExport,
        defaultFeaturedFilterInput,
        useFilterHash,
        lastKnownRowCount,
        getFeaturedFilterCountParams
    }


}