import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Box, Grid, MenuItem, Paper, Skeleton, TextField, Typography} from "@mui/material";
import PageRankingsChart from "./PageRankingsChart";
import {FeaturedRankingStatSkeleton} from "./FeaturedRankingStat";
import SearchConsoleFeaturedStats from "./SearchConsoleFeaturedStats";
import SearchConsoleQueryRankingTable from "./SearchConsoleQueryRankingTable";
import {grey} from "@mui/material/colors";
import {format} from "date-fns";
import globalVars from "../../../styles/globalVars";
import useSearchConsolePerformanceHistory from "../../../hooks/useSearchConsolePerformanceHistory";
import {PostDetailsPanelContentsProps} from "../PostDetailsPanel";

interface ErrorOverlayProps {
    show: boolean;
}

const ErrorOverlay = styled("div")<ErrorOverlayProps>`
  top: 0;
  position: absolute;
  width: 100%;
  height: calc(100%);
  transition: background-color ease-in-out 0.5s;
  background-color: ${props => props.show ? "rgba(0, 0, 0, 0.8)" : "rgba(0, 0, 0, 0)"};
  display: ${props => props.show ? "flex" : "none"};
  justify-content: center;
  align-items: center;
`

const DateFocusMenuItemSubtext = ({children}: { children: string }) => (
    <Typography
        variant={"subtitle1"}
        display={"block"}
        fontSize={"0.75em"}
        color={grey["A700"]}
        sx={{mt: -0.9}}
    >{children}</Typography>
)

const RankingsPanelContents = ({page, dayRanges}: PostDetailsPanelContentsProps) => {
    const panelRef = useRef();
    const [error, setError] = useState<string | null>(null);
    const [rangeOption, setRangeOption] = useState<{ dayRange: number, selectedFocusDateField: 'published' | 'modified' }>({
        dayRange: dayRanges[0],
        selectedFocusDateField: "modified"
    });

    console.log("Range option", rangeOption);

    const selectedDate = page ? page[rangeOption.selectedFocusDateField] : undefined;
    const validDayRanges = dayRanges
        .filter((range, index) => index === 0 || range * 8.64e+7 + selectedDate < new Date().getTime())

    const {
        searchConsoleHistory,
        isLoading,
        error: searchConsoleError,
        searchConsoleHistoryDifference
    } = useSearchConsolePerformanceHistory({
        pageUrl: page?.url,
        pageModifiedDate: selectedDate,
        dayRange: rangeOption.dayRange,
        ref: panelRef
    });

    useEffect(() => {
        setError(null);
    }, [page]);

    useEffect(() => {
        if (Boolean(page)) {
            console.log("Resetting");
            setRangeOption({
                dayRange: [...validDayRanges].sort((a, b) => b - a)[0],
                selectedFocusDateField: 'modified'
            });
        }
        // eslint-disable-next-line
    }, [page]);

    useEffect(() => {
        setError(searchConsoleError);
    }, [searchConsoleError]);

    const noResults = isLoading === false && (searchConsoleHistory === null || searchConsoleHistory.rows.length === 0);

    return <>
        <Paper
            sx={{
                p: 2,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                position: "fixed",
                width: "calc(100% - " + globalVars.drawerWidthExpanded + ")",
                boxSizing: "border-box",
                zIndex: 2
            }}
            elevation={2}
            ref={panelRef}
        >
            <div>
                <Typography variant={"h2"}>Search Performance</Typography>
                <Typography variant={"h3"} sx={{mt: 1}} fontSize={"1.2rem"}>{page?.url}</Typography>
            </div>
            <div>
                <TextField
                    select
                    label={"Focus Range"}
                    sx={{width: "180px", mr: 2}}
                    InputProps={{sx: {height: "56px"}}}
                    value={rangeOption.selectedFocusDateField}
                    onChange={event => setRangeOption({
                        ...rangeOption,
                        dayRange: dayRanges[0],
                        selectedFocusDateField: event.target.value as "modified" | "published"
                    })}
                >
                    <MenuItem
                        value={"modified"}
                        sx={{display: "block"}}
                    >
                        <Typography display={"block"}>Last Updated</Typography>
                        <DateFocusMenuItemSubtext>{format(new Date(page?.modified || 0), "MMM do, yyyy")}</DateFocusMenuItemSubtext>
                    </MenuItem>
                    <MenuItem
                        value={"published"}
                        sx={{display: "block"}}
                    >
                        <Typography display={"block"}>Published Date</Typography>
                        <DateFocusMenuItemSubtext>{format(new Date(page?.published || 0), "MMM do, yyyy")}</DateFocusMenuItemSubtext>
                    </MenuItem>
                </TextField>
                <TextField
                    select
                    label={"Show rankings for"}
                    sx={{width: "140px"}}
                    value={rangeOption.dayRange + ""}
                    onChange={event => setRangeOption({
                        ...rangeOption,
                        dayRange: parseInt(event.target.value)
                    })}
                >
                    {validDayRanges.map(dayRange => (
                        <MenuItem key={dayRange} value={dayRange}>{dayRange + " day range"}</MenuItem>
                    ))}
                </TextField>
            </div>
        </Paper>
        <Box display={"flex"} justifyContent={"center"} maxHeight={"100%"} overflow={"auto"}>
            <Box
                width={"75%"}
                px={4}
                mt={"95px"}
            >
                <Box paddingTop={"calc(" + (1 / 5 * 100) + "% + 42px)"} position={"relative"} mt={2}>
                    <Box position={"absolute"} top={"0"} width={"100%"} height={"100%"}>
                        {isLoading || searchConsoleHistory === null ?
                            <Skeleton height={"100%"} variant={'rectangular'} animation={"wave"}/> :
                            <PageRankingsChart
                                rankingHistory={searchConsoleHistory} centerDate={selectedDate}
                                verticalBarLabel={rangeOption.selectedFocusDateField === "modified" ? "Updated" : "Published"}
                            />
                        }
                    </Box>
                </Box>
                <Grid container spacing={2} sx={{mt: 1}}>
                    <Grid item xs={3}>
                        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} mt={"25px"}>
                            {isLoading || searchConsoleHistory === null || searchConsoleHistoryDifference === null || noResults ? <>
                                <FeaturedRankingStatSkeleton label={"Clicks"} sx={{mb: 2}}/>
                                <FeaturedRankingStatSkeleton label={"Impressions"} sx={{mb: 2}}/>
                                <FeaturedRankingStatSkeleton label={"Avg Position"} sx={{mb: 2}}/>
                            </> : <SearchConsoleFeaturedStats
                                searchConsoleHistory={searchConsoleHistory}
                                searchConsoleHistoryDifference={searchConsoleHistoryDifference}
                            />}
                        </Box>
                    </Grid>
                    <Grid item xs={9}>
                        <SearchConsoleQueryRankingTable
                            searchConsoleHistory={isLoading || searchConsoleHistory === null ? null : searchConsoleHistory}
                            searchConsoleHistoryDifference={searchConsoleHistoryDifference}
                        />
                    </Grid>
                </Grid>
            </Box>
            <ErrorOverlay
                show={(Boolean(error) || noResults) && isLoading === false}>
                <Typography color={grey["A100"]} variant={"h2"}>
                    Search console rankings are not available for this page at the selected date range.
                </Typography>
            </ErrorOverlay>
        </Box>x
    </>
}

export default RankingsPanelContents;