import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import React, {useCallback, useState} from "react";
import {withStyles} from "@mui/styles";
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    ListItemText as OriginalListItemText,
    ListItemTextProps, Typography
} from "@mui/material";
import {NavLink} from "react-router-dom";
import {makeStyles} from '@mui/styles'
import List from "@mui/material/List";
import Collapse from "@mui/material/Collapse";
import {InfoOutlined, LockOutlined} from "@mui/icons-material";
import StarIcon from "@mui/icons-material/Star";
import {AppIcon} from "hashtagjeff-shared-components";
import HtmlTooltip from "../HtmlTooltip";
import UpgradePlanButton from "./UpgradePlanButton";

const useStyles = makeStyles(theme => ({
    navigationItem: {
        whiteSpace: "nowrap",
        marginTop: theme.spacing(1),
        borderRadius: "4px",
        "&.$Mui-selected": {
            backgroundColor: theme.palette.primary.main
        },
        "&:hover": {
            backgroundColor: "rgb(251 144 57 / 50%)"
        },
        "&.$Mui-selected:hover": {
            backgroundColor: "#d57a31"
        }
    }
}));

const ListItemText = withStyles({
    root: {
        color: "#FFFFFF"
    }
})(OriginalListItemText) as React.FC<ListItemTextProps>;

type NavigationItemProps = {
    name: string;
    iconData: { name: string } | React.ReactNode;
    url: string;
    children?: React.ReactNode;
    open?: boolean;
    help: { body?: string };
    selected: boolean;
    disabled?: boolean;
}

const NavigationItem = ({
                            name,
                            iconData,
                            url,
                            selected,
                            children = [],
                            open = false,
                            help,
                            disabled = false
                        }: NavigationItemProps) => {
    const [disabledDialogOpen, setDisabledDialogOpen] = useState(false);
    const classes = useStyles();

    const MaybeWrapLink = useCallback(({children}) => {
        if (disabled) {
            return <>{children}</>
        }
        return <NavLink style={{textDecoration: 'none'}} to={url} data-test={url}>
            {children}
        </NavLink>
    }, [disabled, url]);

    let childrenList = null;
    if (children && (!Array.isArray(children) || children.length > 0)) {
        return <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div">
                {children}
            </List>
        </Collapse>
    }

    let icon = iconData;
    if (disabled) {
        icon = <LockOutlined/>;
    } else if ((iconData as { name: string }).name) {
        icon = <AppIcon iconData={iconData} defaultIcon={StarIcon}/>;
    }

    return <>
        <MaybeWrapLink>
            <ListItem
                button selected={selected}
                className={classes.navigationItem}
                onClick={disabled ? () => setDisabledDialogOpen(true) : null}
            >
                <ListItemIcon>
                    {icon}
                </ListItemIcon>
                <ListItemText>
                    <Typography component={"span"} sx={{color: disabled ? "#a7a7a7" : undefined}}>{name}</Typography>
                    {help && help.body ? <HtmlTooltip
                        help={help}
                        placement={"right"}
                        arrow
                    >
                        <InfoOutlined style={{fontSize: 15, color: disabled ? "#a7a7a7" : undefined}}/>
                    </HtmlTooltip> : ""}
                </ListItemText>
            </ListItem>
        </MaybeWrapLink>
        <Dialog open={disabledDialogOpen} onClose={() => setDisabledDialogOpen(false)}>
            <DialogTitle>Unlock {name}</DialogTitle>
            <DialogContent>
                {help && help.body ?
                    <Typography sx={{mb: 2}}>
                        {help.body}
                    </Typography> : null}
                <Typography>
                    This feature not available on your current plan. Click the button below to unlock {name} (and more!)
                </Typography>
                <Box mt={2} display={"flex"} justifyContent={"center"}>
                    <UpgradePlanButton/>
                </Box>
            </DialogContent>
        </Dialog>
        {childrenList}
    </>;
}

export default NavigationItem;