import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import environment from "../../environment";
import {User} from "../../types/User";
import {Report, ReportUser} from "../../types/Report";
import ReportOwnerAccount from "../../types/ReportOwnerAccount";
import {WPPage} from "../../types/WPPage";
import {GetRowsParams, ServerFormattedSearchInput, UniqueFilterOptions} from "../../api/ReportsApi";
import ReactGA from "react-ga";
import GAEventCategory from "../../types/GAEventCategory";
import {GAEventActionUser} from "../../types/GAEventAction";
import {setJustLoggedOut} from "./appSlice";
import {push} from "redux-first-history";
import BasicReport from "../../types/BasicReport";
import {
    FilterOptionCount,
    Row,
    SearchBarInput,
    SortByData,
    TableContentsResponse
} from "../../components/table/useReportTable";
import {
    FilterData,
    GeneratedFeaturedFilterData,
    ServerFormattedFeaturedFilterInput
} from "../../components/table/useTableFilters";
import {SubscriptionStatus} from "../../types/SubscriptionStatus";
import {GoogleIntegration, GoogleIntegrationNotSetup, GoogleIntegrationSetup} from "../../types/GoogleIntegration";
import {SearchConsoleResults} from "../../types/SearchConsoleResults";
import TrackedPage from "../../types/TrackedPage";
import PostTabPageValue from "../../types/PostTabPageValue";
import {BillingPeriod} from "../../types/BillingPeriod";
import Note from "../../types/Note";
import ReportTodoItem, {TodoItemStatus} from "../../types/ReportTodoItem";
import SavedUserFilter, {TableInput} from "../../types/SavedUserFilter";
import {Plan} from "../../types/Plan";

export type GetFilterOptionCountParams = {
    reportId: number;
    tableId: number;
    filterData: FilterData;
    filterId: number;
    featuredFilterOptions: ServerFormattedFeaturedFilterInput[];
    search: ServerFormattedSearchInput
}

export type GetUniqueFilterOptionsParams = {
    featuredFilterId: number;
    reportId: number;
    tableId: number;
    filterData: FilterData;
}

export type SignupParams = {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
}

export type InviteUserToReportParams = {
    name: string;
    email: string;
    reportId: number;
}

export type ConfigureAnalyticsConnectionParams = {
    reportId: number,
    type: string,
    accountId: string,
    propertyId: string,
    viewId: string
}

export type ConfigureSearchConsoleParams = {
    reportId: number;
    searchConsoleProperty: string;
}

export type GetSearchConsoleRankingsParams = {
    pageUrl: string,
    pageModifiedDate: number,
    dayRange: number,
    reportId: number,
}

export type GetTrackedPageMetaResponse = {
    tracked_page_meta: {
        date_modified: number;
        date_published: number;
    }
}

export type GetBillingPeriodsResponse = {
    periods: BillingPeriod[];
    show_yearly: boolean;
}

export type GetSubscriptionResponse = {
    subscription: {
        status: SubscriptionStatus;
        period?: "month" | "year",
        allow_yearly: boolean;
    }
}

export type GetPostTabPageFieldValuesParams = {
    url: string;
    postTabPage: number;
    report: number;
}

export type GetPostTabPageFieldValuesResponse = {
    fields: PostTabPageValue[];
}

export type GetAllNotesResponse = {
    notes: Note[];
}

export type UpdateNoteResponse = {
    note: Note;
}

export type CreatePageNoteRequestParams = {
    pageUrl: string;
    reportId: number;
    text: string;
}

export type UpdateNoteRequestParams = {
    noteId: number;
    text: string;
}

export type GetAllTodoItemsResponse = {
    todo_items: ReportTodoItem[];
}

export type CreateTodoItemResponse = {
    todo_item: ReportTodoItem;
}

export type CreateTodoItemParams = {
    reportId: number,
    text: string;
    pageUrl?: string | null,
    assignedTo?: number | null
}

export type UpdateTodoItemParams = {
    reportId: number,
    id: number;
    status?: TodoItemStatus,
    text?: string;
    assignedTo?: number | null;
}

export type UpdateTodoItemResponse = {
    todo_item: ReportTodoItem;
}

export type GetPlanResponse = {
    plan: Plan;
}

export type GetAllSavedUserFiltersResponse = {
    saved_user_filters: SavedUserFilter[];
}

export type CreateSavedUserFilterResponse = {
    saved_user_filter: SavedUserFilter;
}

export type CreateSavedUserFilterParams = {
    name: string;
    pageId: number;
    reportId: number;
    tableInputs: TableInput[];
    search: SearchBarInput | {};
}

const mcdBaseUrl = '/hashtagjeff-reports/app/v2/';

export const myContentDashAdminApi = createApi({
    reducerPath: "myContentDashAdmin",
    tagTypes: ['ReportOwner', 'Report', 'Session', 'TrackedPage', 'Note'],
    keepUnusedDataFor: 60 * 60,
    baseQuery: fetchBaseQuery({baseUrl: "/" + environment.wordpressDirectory + "/wp-json/"}),
    endpoints: (builder) => ({
        getSession: builder.query<{ user: User }, void>({
            providesTags: ['Session'],
            query: () => ({
                url: mcdBaseUrl + 'session',
            }),
        }),
        getReport: builder.query<{ report: Report }, number>({
            providesTags: (response) => [{type: 'Report', id: response.report.id}],
            query: (id) => ({
                url: mcdBaseUrl + 'report/' + id
            })
        }),
        getReports: builder.query<{ reports: BasicReport[], accounts: ReportOwnerAccount[] }, void>({
            providesTags: ['ReportOwner', 'Report'],
            query: () => ({
                url: mcdBaseUrl + 'report'
            })
        }),
        getSetting: builder.query<string, string>({
            providesTags: [],
            query: (setting) => ({
                url: mcdBaseUrl + 'setting/' + setting
            }),
            transformResponse: (response: { value: string }) => response.value
        }),
        getNotes: builder.query<GetAllNotesResponse, { reportId: number }>({
            providesTags: ['Note'],
            query: ({reportId}) => ({
                url: mcdBaseUrl + 'note?report_id=' + reportId
            })
        }),
        getWpPage: builder.query<WPPage, number>({
            providesTags: [],
            query: (pageId) => ({
                url: '/wp/v2/pages/' + pageId
            })
        }),
        getRows: builder.query<TableContentsResponse, GetRowsParams>({
            providesTags: [],
            query: ({
                        reportId,
                        tableId,
                        page,
                        perPage,
                        filterData = [],
                        sortByData,
                        featuredFilterOptions,
                        search
                    }) => ({
                url: mcdBaseUrl + 'table/' + tableId + '/action/rows',
                method: 'POST',
                body: {
                    report_id: reportId,
                    page: page,
                    per_page: perPage,
                    filter_data: filterData,
                    sort_by: Object.keys(sortByData),
                    featured_filter_options: featuredFilterOptions,
                    sort_by_directions: Object.values(sortByData),
                    search
                }
            }),
            transformResponse: (response: { rows: Row[], total_rows: number, sort_by: SortByData }) => ({
                rows: response.rows,
                totalRows: response.total_rows,
                tableSortBy: response.sort_by
            })
        }),
        getFilterOptionCounts: builder.query<FilterOptionCount[], GetFilterOptionCountParams>({
            providesTags: [],
            query: (params) => ({
                url: "/hashtagjeff-reports/app/v2/table/" + params.tableId + "/action/filter_option_count",
                method: "POST",
                body: {
                    report_id: params.reportId,
                    filter_data: params.filterData,
                    featured_filter_options: params.featuredFilterOptions,
                    filter_id: params.filterId,
                    search: params.search
                }
            }),
            transformResponse: (response: UniqueFilterOptions) => Object.keys(response.rows[0]).map(property => ({
                featuredFilterId: parseInt(property.replace(/option_filter_(\d+)_count_\d+/, '$1')),
                optionId: parseInt(property.replace(/option_filter_\d+_count_(\d+)/, '$1')),
                count: response.rows[0][property]
            }))
        }),
        getUniqueFilterOptions: builder.query<GeneratedFeaturedFilterData[], GetUniqueFilterOptionsParams>({
            providesTags: [],
            query: (params) => ({
                url: "/hashtagjeff-reports/app/v2/table/" + params.tableId + "/action/auto_unique_database_options",
                method: "POST",
                body: {
                    featured_filter_id: params.featuredFilterId,
                    report_id: params.reportId,
                    filter_data: params.filterData
                }
            }),
            transformResponse: (response: UniqueFilterOptions[], meta, args) => response.map((response) => {
                return {
                    featuredFilterId: args.featuredFilterId,
                    rows: response.rows,
                    cache_key: response.cache_key
                }
            })
        }),
        getSearchConsoleRankings: builder.query<SearchConsoleResults, GetSearchConsoleRankingsParams>({
            query: (params) => ({
                url: "/hashtagjeff-reports/app/v2/report/" + params.reportId + "/action/get_search_console_rankings",
                method: "GET",
                params: {
                    "page_url": params.pageUrl,
                    "page_modified_date": params.pageModifiedDate,
                    "day_range": params.dayRange
                }
            })
        }),
        getTrackedPages: builder.query<{ tracked_pages: TrackedPage[], total: number }, { reportId: number, page: number, perPage: number, search: string }>({
            providesTags: ['TrackedPage'],
            query: (params) => ({
                url: mcdBaseUrl + 'tracked_page',
                params: {
                    report_id: params.reportId,
                    per_page: params.perPage,
                    page: params.page,
                    search: params.search
                }
            })
        }),
        getPageTrackersByUrl: builder.query<{ trackers: number[] }, { reportId: number, pageUrl: string }>({
            query: ({reportId, pageUrl}) => ({
                url: mcdBaseUrl + 'tracked_page/action/get_page_url_trackers',
                method: 'GET',
                params: {
                    report_id: reportId,
                    page_url: pageUrl
                }
            })
        }),
        getTrackedPageMeta: builder.query<GetTrackedPageMetaResponse, number>({
            query: (pageId) => ({
                url: mcdBaseUrl + 'tracked_page/' + pageId + '/action/page_meta',
                method: 'GET'
            })
        }),
        getBillingPeriods: builder.query<GetBillingPeriodsResponse, void>({
            query: () => ({
                url: mcdBaseUrl + 'session/action/get_pricing_options',
                method: 'GET'
            })
        }),
        getPlan: builder.query<GetPlanResponse, { planId: string }>({
            query: ({planId}) => ({
                url: mcdBaseUrl + 'plan/' + encodeURIComponent(planId),
                method: 'GET'
            })
        }),
        getSubscription: builder.query<GetSubscriptionResponse, void>({
            query: () => ({
                url: mcdBaseUrl + 'session/action/get_subscription',
                method: 'GET'
            })
        }),
        getPostTabPageFieldValues: builder.query<GetPostTabPageFieldValuesResponse, GetPostTabPageFieldValuesParams>({
            query: (params) => ({
                url: mcdBaseUrl + 'post_tab_page/' + params.postTabPage + '/action/values',
                params: {
                    url: params.url,
                    report: params.report
                }
            })
        }),
        getReportTodoItems: builder.query<GetAllTodoItemsResponse, { reportId: number }>({
            query: (params) => ({
                url: mcdBaseUrl + 'todo_item',
                params: {
                    report_id: params.reportId
                }
            })
        }),
        getSavedUserFilters: builder.query<GetAllSavedUserFiltersResponse, { reportId: number }>({
            query: (params) => ({
                url: mcdBaseUrl + 'saved_user_filter',
                params: {
                    report_id: params.reportId
                }
            })
        }),
        // MUTATIONS
        deleteSavedUserFilter: builder.mutation<{}, { savedUserFilterId: number, reportId: number }>({
            query: ({savedUserFilterId}) => ({
                url: mcdBaseUrl + 'saved_user_filter/' + savedUserFilterId,
                method: 'DELETE'
            }),
            onQueryStarted: async (arg, {queryFulfilled, dispatch}) => {
                await queryFulfilled;
                dispatch(
                    myContentDashAdminApi.util.updateQueryData('getSavedUserFilters', {
                        reportId: arg.reportId
                    }, (draft) => {
                        draft.saved_user_filters = draft.saved_user_filters.filter((filter) => filter.id !== arg.savedUserFilterId);
                        return draft;
                    })
                );
            }
        }),
        createSavedUserFilter: builder.mutation<CreateSavedUserFilterResponse, CreateSavedUserFilterParams>({
            query: (params) => ({
                url: mcdBaseUrl + 'saved_user_filter',
                method: 'POST',
                body: {
                    report_id: params.reportId,
                    page_id: params.pageId,
                    table_inputs: params.tableInputs,
                    search: params.search,
                    name: params.name
                }
            }),
            onQueryStarted: async (arg, {queryFulfilled, dispatch}) => {
                const response = await queryFulfilled;
                dispatch(
                    myContentDashAdminApi.util.updateQueryData('getSavedUserFilters', {
                        reportId: arg.reportId
                    }, (draft) => {
                        draft.saved_user_filters.push(response.data.saved_user_filter);
                        return draft;
                    })
                );
            }
        }),
        createReportTodoItem: builder.mutation<CreateTodoItemResponse, CreateTodoItemParams>({
            query: (params) => ({
                url: mcdBaseUrl + 'todo_item',
                method: 'POST',
                body: {
                    report_id: params.reportId,
                    text: params.text,
                    page_url: params.pageUrl,
                    assigned_to: params.assignedTo
                }
            }),
            onQueryStarted: async (arg, {queryFulfilled, dispatch}) => {
                const response = await queryFulfilled;
                dispatch(
                    myContentDashAdminApi.util.updateQueryData('getReportTodoItems', {
                        reportId: arg.reportId
                    }, (draft) => {
                        draft.todo_items.push(response.data.todo_item);
                        return draft;
                    })
                );
            }
        }),
        deleteReportTodoItem: builder.mutation<undefined, { id: number, reportId: number }>({
            query: (params) => ({
                url: mcdBaseUrl + 'todo_item/' + params.id,
                method: 'DELETE'
            }),
            onQueryStarted: async (arg, {queryFulfilled, dispatch}) => {
                await queryFulfilled;
                dispatch(
                    myContentDashAdminApi.util.updateQueryData('getReportTodoItems', {
                        reportId: arg.reportId
                    }, (draft) => {
                        draft.todo_items = draft.todo_items.filter(item => item.id !== arg.id);
                        return draft;
                    })
                );
            }
        }),
        updateReportTodoItem: builder.mutation<UpdateTodoItemResponse, UpdateTodoItemParams>({
            query: (params) => ({
                url: mcdBaseUrl + 'todo_item/' + params.id,
                method: 'PUT',
                body: {
                    text: params.text,
                    assignedTo: params.assignedTo,
                    status: params.status
                }
            }),
            onQueryStarted: async (arg, {queryFulfilled, dispatch}) => {
                const patch = dispatch(
                    myContentDashAdminApi.util.updateQueryData('getReportTodoItems', {
                        reportId: arg.reportId
                    }, (draft) => {
                        draft.todo_items = draft.todo_items.map(item => item.id === arg.id ? {
                            ...item,
                            text: arg.text || item.text,
                            status: arg.status || item.status,
                            assigned_to: arg.assignedTo || item.assigned_to
                        } : item);
                        return draft;
                    })
                );
                try {
                    const result = await queryFulfilled;
                    dispatch(
                        myContentDashAdminApi.util.updateQueryData('getReportTodoItems', {
                            reportId: arg.reportId
                        }, (draft) => {
                            draft.todo_items = draft.todo_items.map(item => item.id === arg.id ? result.data.todo_item : item);
                            return draft;
                        })
                    );
                } catch (error) {
                    patch.undo();

                }
            }
        }),
        createPageNote: builder.mutation<{ note: Note }, CreatePageNoteRequestParams>({
            query: (params) => ({
                url: mcdBaseUrl + 'note/',
                body: {
                    report_id: params.reportId,
                    text: params.text,
                    page_url: params.pageUrl
                },
                method: 'POST'
            }),
            onQueryStarted: async (arg, {queryFulfilled, dispatch}) => {
                const response = await queryFulfilled;
                dispatch(
                    myContentDashAdminApi.util.updateQueryData('getNotes', {
                        reportId: arg.reportId
                    }, (draft) => {
                        draft.notes.push(response.data.note);
                        return draft;
                    })
                );
            }
        }),
        updatePageNote: builder.mutation<{ note: Note }, UpdateNoteRequestParams>({
            query: (params) => ({
                url: mcdBaseUrl + 'note/' + params.noteId,
                body: {
                    text: params.text,
                },
                method: 'PUT'
            }),
            onQueryStarted: async (arg, {queryFulfilled, dispatch}) => {
                const response = await queryFulfilled;
                dispatch(
                    myContentDashAdminApi.util.updateQueryData('getNotes', {
                        reportId: response.data.note.report_id
                    }, (draft) => {
                        draft.notes = draft.notes.map(note => note.id === response.data.note.id ? response.data.note : note);
                        return draft;
                    })
                );
            }
        }),
        deletePageNote: builder.mutation<undefined, { noteId: number, reportId: number }>({
            query: (params) => ({
                url: mcdBaseUrl + 'note/' + params.noteId,
                method: 'DELETE'
            }),
            onQueryStarted: async (arg, {queryFulfilled, dispatch}) => {
                await queryFulfilled;
                dispatch(
                    myContentDashAdminApi.util.updateQueryData('getNotes', {
                        reportId: arg.reportId
                    }, (draft) => {
                        draft.notes = draft.notes.filter(note => note.id !== arg.noteId);
                        return draft;
                    })
                );
            }
        }),
        removePageFromTracker: builder.mutation<void, { pageId: number }>({
            invalidatesTags: ['TrackedPage'],
            query: ({pageId}) => ({
                url: mcdBaseUrl + 'tracked_page/' + pageId,
                method: 'DELETE'
            })
        }),
        addPageToTracker: builder.mutation<{ tracked_page: TrackedPage }, { pageUrl: string, reportId: number, trackerId: number }>({
            invalidatesTags: ['TrackedPage'],
            query: ({pageUrl, reportId, trackerId}) => ({
                url: mcdBaseUrl + 'tracked_page',
                method: 'POST',
                body: {
                    report_id: reportId,
                    tracker_id: trackerId,
                    page_url: pageUrl
                }
            }),
            onQueryStarted: async (arg, {queryFulfilled, dispatch}) => {
                const patch = dispatch(
                    myContentDashAdminApi.util.updateQueryData('getPageTrackersByUrl', {
                        reportId: arg.reportId,
                        pageUrl: arg.pageUrl
                    }, (draft) => {
                        draft.trackers.push(arg.trackerId);
                    })
                )
                try {
                    await queryFulfilled;
                } catch (error) {
                    patch.undo();
                }
            }
        }),
        inviteUserToReport: builder.mutation<{ user: ReportUser }, InviteUserToReportParams>({
            query: (params) => ({
                url: mcdBaseUrl + 'report/' + params.reportId + '/action/report_user',
                method: 'POST',
                body: {
                    email: params.email,
                    name: params.name
                }
            }),
            onQueryStarted: async (arg, {queryFulfilled, dispatch}) => {
                try {
                    const newUser = (await queryFulfilled).data.user;
                    dispatch(
                        myContentDashAdminApi.util.updateQueryData('getReport', arg.reportId, (draft) => {
                            draft.report.access_users.push(newUser);
                        })
                    )

                    // Normally we could use the invalidatesTags option, but patching query immediately after it's invalidated doesn't work for some reason
                    dispatch(myContentDashAdminApi.util.invalidateTags([{type: 'Report', id: arg.reportId}]))
                } catch (error) {

                }
            }
        }),
        removeUserFromReport: builder.mutation<void, { userId: number, reportId: number }>({
            query: ({userId, reportId}) => ({
                url: mcdBaseUrl + 'report/' + reportId + '/action/report_user',
                method: "DELETE",
                params: {
                    user_id: userId
                }
            }),
            onQueryStarted: async (arg, {queryFulfilled, dispatch}) => {
                try {
                    await queryFulfilled
                    dispatch(
                        myContentDashAdminApi.util.updateQueryData('getReport', arg.reportId, (draft) => {
                            draft.report.access_users = draft.report.access_users.filter(user => user.id !== arg.userId);
                        })
                    )
                    dispatch(myContentDashAdminApi.util.invalidateTags([{type: 'Report', id: arg.reportId}]))
                } catch (error) {

                }
            }
        }),
        signup: builder.mutation<{ user: User, checkout_url?: string }, SignupParams>({
            invalidatesTags: ['Session'],
            query: (params) => ({
                url: mcdBaseUrl + 'session/action/signup',
                method: 'POST',
                body: {
                    first_name: params.firstName,
                    last_name: params.lastName,
                    email: params.email,
                    password: params.password
                }
            }),
            onQueryStarted: async (arg, {queryFulfilled}) => {
                try {
                    await queryFulfilled;
                    ReactGA.event({
                        category: GAEventCategory.USER,
                        action: GAEventActionUser.SIGNED_UP
                    });
                } catch (error) {

                }
            }
        }),
        generateSubscriptionCheckoutUrl: builder.mutation<string, { period: "year" | "month", plan?: string }>({
            query: ({period, plan}) => ({
                url: mcdBaseUrl + 'session/action/get_checkout_url?period=' + period + '&plan=' + plan,
                method: 'GET',
            }),
            transformResponse: (response: { checkout_url: string }) => response.checkout_url
        }),
        generateUpgradeSubscriptionCheckoutUrl: builder.mutation<string, void>({
            query: () => ({
                url: mcdBaseUrl + 'session/action/get_checkout_url',
                method: 'GET',
                params: {
                    variant: 'upgrade_subscription'
                }
            }),
            transformResponse: (response: { checkout_url: string }) => response.checkout_url
        }),
        generateAddSitesCheckoutUrl: builder.mutation<string, { quantity: number }>({
            query: ({quantity}) => ({
                url: mcdBaseUrl + 'session/action/get_checkout_url',
                method: 'GET',
                params: {
                    variant: 'add_sites',
                    quantity
                }
            }),
            transformResponse: (response: { checkout_url: string }) => response.checkout_url
        }),
        sendPasswordResetEmail: builder.mutation<void, { email: string }>({
            query: ({email}) => ({
                url: mcdBaseUrl + 'session/action/send_password_reset',
                method: 'POST',
                body: {
                    email,
                }
            })
        }),
        resetUserPassword: builder.mutation<void, { email: string, resetToken: string, password: string }>({
            query: (params) => ({
                url: mcdBaseUrl + 'session/action/reset_password',
                method: 'POST',
                body: {
                    email: params.email,
                    reset_token: params.resetToken,
                    password: params.password
                }
            })
        }),
        confirmSubscription: builder.mutation<{ subscription_status: SubscriptionStatus, customer_id: string }, string>({
            query: (subscriptionId) => ({
                url: mcdBaseUrl + 'session/action/confirm_subscription',
                method: 'POST',
                body: {
                    subscription_id: subscriptionId
                }
            }),
            onQueryStarted: async (arg, {queryFulfilled, dispatch}) => {
                try {
                    const response = await queryFulfilled
                    dispatch(
                        myContentDashAdminApi.util.updateQueryData('getSession', undefined, (draft) => {
                            if (!draft.user) {
                                return;
                            }
                            draft.user.subscription_status = response.data.subscription_status;
                            draft.user.customer_id = response.data.customer_id;
                        })
                    )
                    dispatch(myContentDashAdminApi.util.invalidateTags(['Session', 'Report']))
                } catch (err) {
                }
            }
        }),
        setupWordpressIntegration: builder.mutation<{ report: Report, is_new: boolean, code: string }, { code: string, websiteUrl: string }>({
            query: ({code, websiteUrl}) => ({
                url: mcdBaseUrl + 'report/action/connect_wordpress_website',
                method: "POST",
                body: {
                    code,
                    website_url: websiteUrl
                }
            })
        }),
        logIn: builder.mutation<User | null, { email: string, password: string }>({
            invalidatesTags: ['Session'],
            query: ({email, password}) => ({
                url: mcdBaseUrl + 'session',
                method: 'POST',
                body: {
                    username: email,
                    password
                }
            }),
            transformResponse: (response: { user: User }) => response.user,
            onQueryStarted: async (arg, {queryFulfilled, dispatch}) => {
                try {
                    const response = await queryFulfilled
                    dispatch(
                        myContentDashAdminApi.util.updateQueryData('getSession', undefined, (draft) => {
                            draft.user = response.data;
                        })
                    )
                    dispatch(myContentDashAdminApi.util.invalidateTags(['Report']))

                    ReactGA.event({
                        category: GAEventCategory.USER,
                        action: GAEventActionUser.LOGGED_IN
                    });
                } catch (err) {
                }
            }
        }),
        logOut: builder.mutation<void, void>({
            invalidatesTags: [],
            query: () => ({
                url: mcdBaseUrl + 'session/action/log_out',
                method: 'POST'
            }),
            async onQueryStarted(body, {dispatch, queryFulfilled}) {
                dispatch(myContentDashAdminApi.util.updateQueryData('getSession', undefined, (draft) => {
                    draft.user = null;
                }));
                dispatch(push('/login'));
                await queryFulfilled
                dispatch(myContentDashAdminApi.util.invalidateTags(['Report', 'ReportOwner']))
                dispatch(setJustLoggedOut(true));

                ReactGA.event({
                    category: GAEventCategory.USER,
                    action: GAEventActionUser.LOGGED_OUT
                });
            }
        }),
        authenticateGoogleAuthCode: builder.mutation<GoogleIntegration, { code: string, reportId: number }>({
            query: ({code, reportId}) => ({
                url: mcdBaseUrl + "report/" + reportId + "/action/authenticate_google",
                body: {code},
                method: "POST"
            }),
            onQueryStarted: async (args, {queryFulfilled, dispatch}) => {
                try {
                    const {data: google} = await queryFulfilled;
                    dispatch(myContentDashAdminApi.util.updateQueryData('getReport', args.reportId, (draft) => {
                        draft.report.integrations.google = google;
                    }));
                } catch (e) {
                }
            }
        }),
        configureAnalyticsConnection: builder.mutation<void, ConfigureAnalyticsConnectionParams>({
            query: (params) => ({
                url: mcdBaseUrl + "report/" + params.reportId + "/action/configure_analytics_connection",
                method: 'POST',
                body: {
                    account_id: params.accountId,
                    type: params.type,
                    property_id: params.propertyId,
                    view_id: params.viewId
                }
            }),
            onQueryStarted: async (args, {queryFulfilled, dispatch}) => {
                try {
                    await queryFulfilled;
                    dispatch(myContentDashAdminApi.util.updateQueryData('getReport', args.reportId, (draft) => {
                        if (args.type === "UA") {
                            (draft.report.integrations.google as GoogleIntegrationSetup).analytics = {
                                account: args.accountId,
                                property: args.propertyId,
                                view: args.viewId
                            };

                            (draft.report.integrations.google as GoogleIntegrationSetup).analytics4 = {
                                account: null,
                                property: null
                            }
                        } else {
                            (draft.report.integrations.google as GoogleIntegrationSetup).analytics4 = {
                                account: args.accountId,
                                property: args.propertyId
                            };

                            (draft.report.integrations.google as GoogleIntegrationSetup).analytics = {
                                account: null,
                                property: null,
                                view: null
                            }
                        }

                        (draft.report.integrations.google as GoogleIntegrationSetup).analytics_type = args.type;
                    }));

                    ReactGA.event({
                        category: GAEventCategory.REPORT,
                        action: GAEventActionUser.CONNECTED_GOOGLE_ANALYTICS
                    });
                } catch (e) {
                }
            }
        }),
        deleteAnalyticsConfiguration: builder.mutation<void, { reportId: number }>({
            query: ({reportId}) => ({
                url: mcdBaseUrl + "report/" + reportId + "/action/analytics_configuration",
                method: 'DELETE'
            }),
            onQueryStarted: async (args, {queryFulfilled, dispatch}) => {
                try {
                    await queryFulfilled;
                    dispatch(myContentDashAdminApi.util.updateQueryData('getReport', args.reportId, (draft) => {
                        (draft.report.integrations.google as GoogleIntegrationSetup).analytics_type = "GA4";
                        (draft.report.integrations.google as GoogleIntegrationSetup).analytics = {
                            account: null,
                            property: null,
                            view: null
                        };
                        (draft.report.integrations.google as GoogleIntegrationSetup).analytics4 = {
                            account: null,
                            property: null
                        }
                    }));
                } catch (e) {
                }
            }
        }),
        configureSearchConsoleConnection: builder.mutation<void, ConfigureSearchConsoleParams>({
            query: (params) => ({
                url: mcdBaseUrl + "report/" + params.reportId + "/action/configure_search_console_connection",
                method: 'POST',
                body: {
                    property_url: params.searchConsoleProperty
                }
            }),
            onQueryStarted: async (args, {queryFulfilled, dispatch}) => {
                try {
                    await queryFulfilled;
                    dispatch(myContentDashAdminApi.util.updateQueryData('getReport', args.reportId, (draft) => {
                        (draft.report.integrations.google as GoogleIntegrationSetup).search_console = {
                            property_domain: args.searchConsoleProperty
                        }
                    }));
                } catch (e) {
                }
            }
        }),
        deleteSearchConsoleConfiguration: builder.mutation<void, { reportId: number }>({
            query: ({reportId}) => ({
                url: mcdBaseUrl + "report/" + reportId + "/action/search_console_configuration",
                method: 'DELETE'
            }),
            onQueryStarted: async (args, {queryFulfilled, dispatch}) => {
                try {
                    await queryFulfilled;
                    dispatch(myContentDashAdminApi.util.updateQueryData('getReport', args.reportId, (draft) => {
                        (draft.report.integrations.google as GoogleIntegrationSetup).search_console = {
                            property_domain: null
                        }
                    }));
                } catch (e) {
                }
            }
        }),
        resetGoogleConnection: builder.mutation<{ authorization_url: string }, { reportId: number }>({
            query: ({reportId}) => ({
                url: mcdBaseUrl + "report/" + reportId + "/action/google_connection",
                method: 'DELETE'
            }),
            onQueryStarted: async (args, {queryFulfilled, dispatch}) => {
                try {
                    const response = await queryFulfilled;
                    dispatch(myContentDashAdminApi.util.updateQueryData('getReport', args.reportId, (draft) => {
                        (draft.report.integrations.google as GoogleIntegrationNotSetup) = {
                            is_connected: false,
                            authorization_url: response.data.authorization_url
                        }
                    }));
                } catch (e) {
                }
            }
        }),
        startBillingPortalSession: builder.mutation<string, void>({
            query: () => ({
                url: mcdBaseUrl + "session/action/start_billing_portal_session",
                method: "POST"
            }),
            transformResponse: (response: { url: string }) => response.url
        }),
        getSwitchBillingPeriodCheckoutUrl: builder.mutation<{ checkout_url: string }, { newPeriod: "year" | "month" }>({
            query: ({newPeriod}) => ({
                url: mcdBaseUrl + "session/action/switch_billing_period?new_period=" + newPeriod,
                method: "POST"
            })
        })
    })
})

export const {
    useGetSessionQuery,
    useGetReportsQuery,
    useGetSettingQuery,
    useGetNotesQuery,
    useGetWpPageQuery,
    useGetReportQuery,
    useGetRowsQuery,
    useGetFilterOptionCountsQuery,
    useGetTrackedPagesQuery,
    useGetPageTrackersByUrlQuery,
    useGetSubscriptionQuery,
    useGetBillingPeriodsQuery,
    useGetPlanQuery,
    useGetPostTabPageFieldValuesQuery,
    useGetReportTodoItemsQuery,
    useCreateReportTodoItemMutation,
    useUpdateReportTodoItemMutation,
    useDeleteReportTodoItemMutation,
    useCreatePageNoteMutation,
    useUpdatePageNoteMutation,
    useDeletePageNoteMutation,
    useAddPageToTrackerMutation,
    useRemovePageFromTrackerMutation,
    useInviteUserToReportMutation,
    useGetSearchConsoleRankingsQuery,
    useGetTrackedPageMetaQuery,
    useGetSavedUserFiltersQuery,
    useDeleteSavedUserFilterMutation,
    useCreateSavedUserFilterMutation,
    useRemoveUserFromReportMutation,
    useSignupMutation,
    useGenerateSubscriptionCheckoutUrlMutation,
    useGenerateAddSitesCheckoutUrlMutation,
    useSetupWordpressIntegrationMutation,
    useSendPasswordResetEmailMutation,
    useResetUserPasswordMutation,
    useConfirmSubscriptionMutation,
    useLogInMutation,
    useLogOutMutation,
    useAuthenticateGoogleAuthCodeMutation,
    useConfigureAnalyticsConnectionMutation,
    useDeleteAnalyticsConfigurationMutation,
    useConfigureSearchConsoleConnectionMutation,
    useDeleteSearchConsoleConfigurationMutation,
    useResetGoogleConnectionMutation,
    useStartBillingPortalSessionMutation,
    useGetSwitchBillingPeriodCheckoutUrlMutation,
    useGenerateUpgradeSubscriptionCheckoutUrlMutation,
} = myContentDashAdminApi;