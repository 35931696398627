import React, {FC, useRef, useState} from "react";
import {Button, ButtonProps} from "@mui/material";
import Popover from "@mui/material/Popover";
import PresetFilterOptions, {PresetFilterOptionsProps} from "./PresetFilterOptions";
import SearchFilter, {SearchFilterProps} from "./SearchFilter";
import Box from "@mui/material/Box";
import FilterIcon from "@mui/icons-material/FilterList";
import {AppIcon} from "hashtagjeff-shared-components";
import UniqueFilterOptions, {UniqueFilterOptionsProps, UniqueFilterOptionsValue} from "./UniqueFilterOptions";
import HtmlTooltip from "../../HtmlTooltip";
import {FeaturedFilter, FeaturedFilterInput, FeaturedFilterInputValue} from "../../table/useTableFilters";
import isEqual from "react-fast-compare";
import PresetFilterOptionsRadio from "./PresetFilterOptionsRadio";
import PageNotesFilter, {PageNotesFilterProps} from "./PageNotesFilter";

export type FieldFeaturedFilterProps = {
    featuredFilter: FeaturedFilter,
    value: FeaturedFilterInputValue,
    onChange: (newInput: FeaturedFilterInputValue) => void,
    defaultFilterInput: FeaturedFilterInput
} & Partial<ButtonProps>;

const FieldFeaturedFilter = ({
                                 featuredFilter,
                                 value,
                                 onChange,
                                 defaultFilterInput,
                                 ...props
                             }: FieldFeaturedFilterProps) => {
    const [open, setOpen] = useState(false);
    const buttonRef = useRef();

    let PopoverContents:
        FC<PresetFilterOptionsProps> |
        FC<SearchFilterProps> |
        FC<UniqueFilterOptionsProps> |
        FC<PageNotesFilterProps> |
        null
        = null;
    switch (featuredFilter.type) {
        case "filter_options":
            PopoverContents = PresetFilterOptions;
            break;
        case "unique_database_options":
        case "unique_database_options_csv":
        case "unique_database_options_ssv":
            PopoverContents = UniqueFilterOptions;
            break;
        case "page_note":
            PopoverContents = PageNotesFilter;
            break;
        case "search":
            PopoverContents = SearchFilter;
            break;
    }
    if (featuredFilter.type === "filter_options" && featuredFilter.radio_mode) {
        PopoverContents = PresetFilterOptionsRadio;
    }

    const isChanged = Array.isArray(value) ?
        !isEqual([...(defaultFilterInput.input as number[])].sort(), [...value].sort()) :
        !isEqual(defaultFilterInput.input, value);

    return <>
        <HtmlTooltip
            help={featuredFilter.help}
            placement={"top"}
            arrow
        >
            <Button
                startIcon={<AppIcon iconData={featuredFilter.start_icon} defaultIcon={FilterIcon}/>}
                variant={"outlined"}
                color={isChanged ? "primary" : "secondary"}
                onClick={() => setOpen(true)}
                {...props}
                ref={buttonRef}
                data-test={"featured-filter-" + featuredFilter.label}
            >{featuredFilter.label}</Button>
        </HtmlTooltip>
        <Popover
            open={open}
            onClose={() => setOpen(false)}
            anchorEl={buttonRef.current}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <Box p={2} data-test={"featured-filter-modal-" + featuredFilter.label}>
                <PopoverContents
                    value={value as (number[] & string & UniqueFilterOptionsValue)}
                    onChange={onChange}
                    featuredFilter={featuredFilter}
                    closePopover={() => setOpen(false)}
                />
            </Box>
        </Popover>
    </>;
}

FieldFeaturedFilter.getDefaultValue = (featuredFilter: FeaturedFilter) => {
    switch (featuredFilter.type) {
        case "filter_options":
            return featuredFilter.radio_mode ? [] : featuredFilter.filter_options.filter(option => option.default_checked).map(option => option.id)
        case "unique_database_options":
        case "unique_database_options_csv":
        case "unique_database_options_ssv":
            return {selected: [], mode: 'deselected' as 'selected' | 'deselected'};
        case "search":
            return "";
        case "page_note":
            return {
                exists: null,
                contains: null
            };
        default:
            return null;
    }
}

FieldFeaturedFilter.convertToFilterData = (value, field, featuredFilter) => {
    switch (featuredFilter.type) {
        case "search":
            if (value === "") {
                return null;
            }

            return [[{
                field: field.id,
                compare_type: "contains",
                value: value
            }]];
        case "filter_options":
            return featuredFilter.filter_options.filter(filterOption => value.indexOf(filterOption.id) !== -1).map(filterOption => filterOption.filter_data);
        default:
            return null;
    }
}

export default FieldFeaturedFilter;