import environment from "../environment";
import ReportsApiError from "./error/ReportsApiError";
import JsFileDownloader from 'js-file-downloader';
import {
    FilterData,
    GeneratedFeaturedFilterRow,
    ServerFormattedFeaturedFilterInput
} from "../components/table/useTableFilters";
import {SortByData} from "../components/table/useReportTable";
import {Export} from "../components/table/useTableExport";

export type UniqueFilterOptions = {
    rows: GeneratedFeaturedFilterRow[];
    cache_key: string;
}

export type RunExportParams = {
    reportId: number;
    tableId: number;
    filterData: FilterData;
    sortByData: SortByData;
    search: ServerFormattedSearchInput;
    featuredFilterOptions: ServerFormattedFeaturedFilterInput[];
    exportId?: number;
    abortController?: AbortController;
}

export type GetRowsParams = {
    reportId: number;
    tableId: number;
    page: number;
    perPage: number;
    filterData: FilterData;
    sortByData: SortByData;
    featuredFilterOptions: ServerFormattedFeaturedFilterInput[];
    search: ServerFormattedSearchInput
};

export type ServerFormattedSearchInput = {
    search_term?: string;
    column_ids?: number[];
    use_advanced_search?: boolean;
}

class ReportsApi {

    static downloadExport = async (exportId: number) => {
        return new JsFileDownloader({
            url: ReportsApi.getApiUrl("export/" + exportId)
        });
    }

    static downloadWordPressCompanionPlugin = async () => {
        return new JsFileDownloader({
            url: ReportsApi.getApiUrl("session/action/download_wordpress_plugin")
        });
    }

    static getApiUrl = (endpoint: string): string => (
        "/" + environment.wordpressDirectory + "/wp-json/hashtagjeff-reports/app/v2/" + endpoint
    )

    static getUniqueFilterOptions = (
        {
            featuredFilterId,
            reportId,
            tableId,
            filterData = []
        }: {
            featuredFilterId: number;
            reportId: number;
            tableId: number;
            filterData: FilterData;
        }, abortController) => {
        return ReportsApi.makeRequest("table/" + tableId + "/action/auto_unique_database_options", "POST", {
            featured_filter_id: featuredFilterId,
            report_id: reportId,
            filter_data: filterData
        }, abortController) as Promise<UniqueFilterOptions>;
    }

    static makeRequest = async (endpoint: string, method: string, parameters: object = {}, abortController: AbortController | null = null, url: string | null = null) => {
        if (!url)
            url = ReportsApi.getApiUrl(endpoint);

        let data: RequestInit = {
            method: method,
            headers: {
                "Content-Type": "application/json; charset=UTF-8"
            },
            credentials: "include" as RequestCredentials,
            signal: undefined,
            body: undefined
        };

        if (abortController) {
            data.signal = abortController.signal;
        }

        if (method === "GET" && Object.keys(parameters).length > 0) {
            const keyValuePairs = [];
            for (let key of Object.keys(parameters)) {
                keyValuePairs.push(key + "=" + encodeURIComponent(parameters[key]));
            }
            url += "?" + keyValuePairs.join("&");
        } else if (method !== "GET") {
            data.body = JSON.stringify(parameters);
        }

        const response = await fetch(url, data);
        let responseJson = null;
        try {
            responseJson = await response.json();
        } catch (error) {
            console.error(`An unexpected error occurred, the server did not return in JSON format.\n
            ${error.message}\n${error.stack}`);
            throw new ReportsApiError("unexpected_error", "An internal server error occurred, please try again");
        }
        if (!response.ok) {
            throw new ReportsApiError(response.status, responseJson.message, responseJson);
        }

        return responseJson;
    }

    static runExport = ({
                            reportId,
                            tableId,
                            filterData,
                            sortByData,
                            search,
                            featuredFilterOptions,
                            exportId = undefined,
                            abortController = undefined
                        }: RunExportParams) => {
        return ReportsApi.makeRequest("export", "POST", {
            report_id: reportId,
            table_id: tableId,
            filter_data: filterData,
            sort_by: Object.keys(sortByData),
            sort_by_directions: Object.values(sortByData),
            featured_filter_options: featuredFilterOptions,
            search,
            export_id: exportId
        }, abortController) as Promise<Export>;
    }
}

declare global {
    interface Window {
        ReportsApi: typeof ReportsApi;
    }
}

window.ReportsApi = ReportsApi;

export default ReportsApi;