import React, {FC, forwardRef, useContext, useState} from 'react';
import {Badge, BadgeProps, Button, IconButton, IconButtonProps, Popover, styled} from "@mui/material";
import AddTodoItemTextField from "./AddTodoItemTextField";
import FormatListNumberedOutlinedIcon from '@mui/icons-material/FormatListNumberedOutlined';
import useForwardedRef from "../../hooks/useForwardedRef";
import {useSnackbar} from "notistack";
import extractPathFromUrl from "../../util/extractPathFromUrl";
import {useGetReportTodoItemsQuery} from "../../store/slice/myContentDashAdminApi";
import ReportContext from "../../context/ReportContext";
import {useAppDispatch} from "../../store/store";
import {openTodoPanel, setSearch} from "../../store/slice/todoPanelSlice";

const StyledBadge = styled(Badge)<BadgeProps>(() => ({
    '& .MuiBadge-badge': {
        right: 8,
        top: 9,
        height: '14px',
        minWidth: '14px',
        width: '14px',
        fontSize: '0.6rem'
    },
})) as FC<BadgeProps>;

const AddTodoActionButton = forwardRef<HTMLButtonElement, Omit<IconButtonProps, 'onClick'> & { pageUrl: string }>((
    ({
         pageUrl,
         ...props
     }, forwardedRef) => {
        const [popoverOpen, setPopoverOpen] = useState(false);
        const ref = useForwardedRef(forwardedRef);
        const {enqueueSnackbar} = useSnackbar();
        const {report} = useContext(ReportContext);
        const {data: todoListResponse} = useGetReportTodoItemsQuery({reportId: report.id});
        const dispatch = useAppDispatch();

        const onTodoAdded = () => {
            setPopoverOpen(false);
            enqueueSnackbar(`Added todo note to ${extractPathFromUrl(pageUrl)}`, {variant: "success"});
        }

        const openTodoPanelForPage = () => {
            dispatch(setSearch(extractPathFromUrl(pageUrl)));
            dispatch(openTodoPanel());
            setPopoverOpen(false);
        }

        const todoItemsCount = todoListResponse?.todo_items.filter(item => item.page_url === pageUrl && item.status !== "done").length || undefined;

        return <>
            <StyledBadge
                badgeContent={todoItemsCount}
                color={"primary"}
            >
                <IconButton
                    {...props}
                    ref={ref}
                    onClick={() => setPopoverOpen(true)}
                >
                    <FormatListNumberedOutlinedIcon/>
                </IconButton>
            </StyledBadge>
            <Popover
                open={popoverOpen}
                onClose={() => setPopoverOpen(false)}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                PaperProps={{sx: {p: 1}}}
                anchorEl={ref.current}
            >
                <AddTodoItemTextField
                    sx={{width: '350px'}}
                    pageUrl={pageUrl}
                    onAdded={onTodoAdded}
                />
                {todoItemsCount ?
                    <Button
                        color={"secondary"}
                        size={"small"}
                        sx={{textTransform: "none", mt: 1}}
                        variant={"outlined"}
                        onClick={openTodoPanelForPage}
                    >See todo items for this page</Button> : null}
            </Popover>
        </>
    })
);


export default AddTodoActionButton;